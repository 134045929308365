<template>
  <div class="home">
    <div class="home-banner">
<!--      <img src="@/assets/banner/1.png" alt="" />-->
      <template v-if="localStorageType==1">
        <img src="@/assets/banner/yiliao_zi.png" alt="开源医疗" />
      </template>
      <template v-else-if="localStorageType==2">
        <img src="@/assets/banner/qiye_zi.png" alt="开源企业" />
      </template>


      <div class="home-banner-content">
<!--        <el-button round color="rgba(0, 0, 0, 0.2)">-->
<!--          播放视频<el-icon-->
<!--            class="el-icon&#45;&#45;right el-icon-video-play"-->
<!--            color="rgba(0, 0, 0, 0.4)"-->
<!--          />-->
<!--        </el-button>-->
<!--        <h1>开启中国开源医疗新生态</h1>-->
<!--        <el-button type="primary">开始免费试用</el-button>-->
<!--        <el-button type="primary" plain>预约演示</el-button>-->
      </div>
    </div>
    <div class="home-info">
      <div class="home-info-cell">
        <img src="@/assets/icon/1.png" alt="" />
        <div>
          <p class="title">核心产品开源共享</p>
          <p class="desc">代码质量严格把控、技术支持稳定输出</p>
        </div>
      </div>
      <div class="home-info-cell">
        <img src="@/assets/icon/2.png" alt="" />
        <div v-if="localStorageType==1">
          <p class="title">百万基层医疗机构</p>
          <p class="desc">降低社会投入成本、提高行业经济价值</p>
        </div>
        <div v-if="localStorageType==2">
          <p class="title">百万中小微型企业</p>
          <p class="desc">降低社会投入成本、提高行业经济价值</p>
        </div>
      </div>
      <div class="home-info-cell">
        <img src="@/assets/icon/3.png" alt="" />
        <div>
          <p class="title">开源社区专注体验</p>
          <p class="desc">拉齐业态信息壁垒、提升项目交互效率</p>
        </div>
      </div>
    </div>
    <div id="production" class="home-resolve">


      <template v-if="localStorageType==1">
        <h1 class="home-title">面向丰富业务场景的医疗解决方案</h1>
        <div class="divider">
          <label/>
        </div>
        <h2 class="home-subtitle">MEDICAL SOLUTIONS</h2>
      </template>
      <template v-else-if="localStorageType==2">
        <h1 class="home-title">面向丰富业务场景的企业应用解决方案</h1>
        <div class="divider">
          <label/>
        </div>
        <h2 class="home-subtitle">ENTERPRISE   SOLUTIONS</h2>
      </template>



      <div class="home-resolve-banner">
        <img src="@/assets/banner/2.png" alt="" />
        <div class="home-resolve-banner-content">
          <el-button plain type="primary"  @click="toAllVersion()" style="color: #ff6200">
            所有版本<el-icon
              class="el-icon--right el-icon-right"
              color="rgba(0, 0, 0, 0.4)"
            />
          </el-button>
        </div>
      </div>
      <div class="home-resolve-list">
        <div
          v-for="(value, key) in versionCompanyProductMap"
          :key="key"
          class="home-resolve-cell"
        >
          <template v-if="value!=null&&value[0]!=null">
          <img :src="value[0].productLogo" alt="" />
          <div>
            <p class="title">{{ value[0].productName }}</p>
            <p class="desc">发布日期：{{ formatDateTime(value[0].createTime) }}</p>
            <p class="desc">{{ value[0].productIntroduction }}</p>
            <table>
              <tr v-for="item in value" :key="item.id">
                <td class="left-column">{{item.moduleName}}</td>
                <td class="right-column">

                  <div class="op1">

                    <span v-if="item.sourceCodeId!=null" @click="gotoDetail(item.sourceCodeId)">
                源代码 <i class="fa fa-cloud-download" />
              </span>
              <span v-if="item.installFileId!=null"  @click="gotoDetail(item.installFileId)">
                安装包 <i class="fa fa-cloud-download" />
              </span>
                    <span v-if="item.documentId!=null" @click="gotoDetail(item.documentId)">
                技术文档 <i class="fa fa-cloud-download" />
              </span>
                  </div>

                </td>
              </tr>
            </table>

          </div>
          </template>
        </div>


        <div
            class="home-resolve-cell"
            v-if="whitePaper.productName!=null"
        >
          <img :src="whitePaper.productLogo" alt="" />
          <div>
            <p class="title">{{ whitePaper.productName }}</p>
            <p class="desc">发布日期：{{ formatDateTime(whitePaper.createTime) }}</p>
            <p class="desc">{{ whitePaper.productIntroduction }}</p>
            <table>
              <tr>
                <td class="left-column">白皮书</td>
                <td class="right-column">

                  <div class="op1">
              <span @click="gotoDetail(whitePaper.productId)">
                下载 <i class="fa fa-cloud-download" />
              </span>
                  </div>

                </td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </div>
    <div id="news" class="home-news" v-if="newsList1.length > 0">
      <h1 class="home-title">产品知识</h1>
      <div class="divider">
        <label />
      </div>
      <h2 class="home-subtitle">PRODUCT KNOWLEDGE</h2>
      <div class="home-resolve-list">
        <el-card  class="image-container" v-for="item in newsList1" :key="item.id">
          <img @click="goNewDetail(item.linkUrl)" :src="!item.infoPicture ? require('@/assets/banner/4.png') : item.infoPicture " alt="建议大小为180px * 320px的图片" style="height: 180px;width: 320px"/>
          <p  @click="goNewDetail(item.linkUrl)" style="font-size: 16px ;font-weight: bold; color: #0f1010 " class="truncate" v-html="truncatedInfoTitle(item.infoTitle)"></p>
          <span style="font-size: 12px;color: #a1a5a6"> {{item.createTime | formatDateTime}}</span>
        </el-card>
      </div>
    </div>
    <div id="news" class="home-news" v-if="newsList2.length > 0">
      <h1 class="home-title">应用案例</h1>
      <div class="divider">
        <label />
      </div>
      <h2 class="home-subtitle">APPLICATION CASES</h2>
      <div class="home-resolve-list">
        <el-card  class="image-container" v-for="item in newsList2" :key="item.id">
          <img @click="goNewDetail(item.linkUrl)" :src="!item.infoPicture ? require('@/assets/banner/4.png') : item.infoPicture " alt="建议大小为180px * 320px的图片" style="height: 180px;width: 320px"/>
          <p  @click="goNewDetail(item.linkUrl)" style="font-size: 16px ;font-weight: bold; color: #0f1010 " class="truncate" v-html="truncatedInfoTitle(item.infoTitle)"></p>
          <span style="font-size: 12px;color: #a1a5a6"> {{item.createTime | formatDateTime}}</span>
        </el-card>
      </div>
    </div>
    <div id="news" class="home-news"  v-if="newsList3.length > 0">
      <h1 class="home-title">行业资讯</h1>
      <div class="divider">
        <label />
      </div>
      <h2 class="home-subtitle">INDUSTRY INFORMATION</h2>
      <div class="home-resolve-list">
          <el-card  class="image-container" v-for="item in newsList3" :key="item.id">
          <img @click="goNewDetail(item.linkUrl)" :src="!item.infoPicture ? require('@/assets/banner/4.png') : item.infoPicture " alt="建议大小为180px * 320px的图片" style="height: 180px;width: 320px"/>
            <p  @click="goNewDetail(item.linkUrl)" style="font-size: 16px ;font-weight: bold; color: #0f1010 " class="truncate" v-html="truncatedInfoTitle(item.infoTitle)"></p>
           <span style="font-size: 12px;color: #a1a5a6"> {{item.createTime | formatDateTime}}</span>
           </el-card>
      </div>
    </div>

    <div class="home-banner2">
      <template v-if="localStorageType==1">
        <img id="case" class="home-select" src="@/assets/banner/3.png" alt="" />
      </template>
      <template v-else-if="localStorageType==2">
        <img id="case" class="home-select" src="@/assets/banner/31.png" alt="" />
      </template>

      <div class="home-banner2-content2">
        <h1 class="home-banner2-title2" style="margin-top:20px;margin-bottom:10px">众多客户的共同选择</h1>
        <div class="divider" style="margin: 0 0 20px 0;">
          <label />
        </div>
        <h2 class="home-banner2-subtitle2">COOPERATIVE PARTNER</h2>
      </div>
    </div>
  </div>

</template>

<script>
import { getCompanyInfo, getCompanyProduct, getInfoNewsList, getWhitePaper} from "@/api/product";
import moment from "moment";
// import {getUserInfo} from "@/api/accountCenter";

export default {
  data() {
    return {
      companyProductList: [],
      versionCompanyProductMap: {},
      whitePaper:{},
      newsList1: [],
      newsList2: [],
      newsList3: [],
      productId: 0,
      companyInfoData: {},
      localStorageType: localStorage.getItem("type") || 2,
    };
  },
  filters: {
    formatDateTime(dateTimeString) {
      const dateTime = new Date(dateTimeString);
      return dateTime.toLocaleString('zh-CN', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
       // hour: '2-digit',
       // minute: '2-digit',
       // second: '2-digit'
      });
    }
  },
  methods: {
    // 获取接口数据
    getData() {
      // let id = this.$route.params.id;
      getCompanyProduct({ companyId: 449 }).then((res) => {
        if (res.code === 200) {
          this.versionCompanyProductMap = res.data;
          // if (res.data && res.data[0]) {
          //   this.productId = res.data[0].productId;
          // }
          // res.data.map((item) => {
          //   item.createTime = moment(item.createTime).format("YYYY-MM-DD");
          // });
        }
      });
      getWhitePaper({ companyId: 449 }).then((res) => {
        if (res.code === 200) {
          this.whitePaper = res.data;
        }
      });

      getCompanyInfo({ companyId: 449 }).then((res) => {
        if (res.code === 200) {
          this.companyInfoData = res.data;
          this.companyInfoData.companyLogo = this.companyInfoData.companyLogo
            ? this.companyInfoData.companyLogo
            : "/imgs/product/companyLogo.png";
        }
      });

      getInfoNewsList({newsType: 1}).then((res) => {
        if (res.code === 200) {
          var newsListDto = res.data;
          this.newsList1 = newsListDto.infoNewsList;
        }
      });
      getInfoNewsList({newsType: 2}).then((res) => {
        if (res.code === 200) {
          var newsListDto = res.data;
          this.newsList2 = newsListDto.infoNewsList;
        }
      });
      getInfoNewsList({newsType: 3}).then((res) => {
        if (res.code === 200) {
          var newsListDto = res.data;
          this.newsList3 = newsListDto.infoNewsList;
        }
      });
    },

    // 跳转到指定页面
    gotoDetail(id) {
      //先校验是否登录，先登录才能下载
      if (localStorage.getItem('token') || this.$store.state.user.token) {
        /*this.$router.push({
          name: "detail",
          params: {id},
        });*/
        let routerJump = this.$router.resolve({ path: '/detail/'+id});
        window.open(routerJump.href, '_blank');
      } else {
        this.$message.error("您还未登录，请登录后下载！");
        this.$Bus.$emit('showLogin', 'show')
        return new Promise( () => {})
      }

      /*this.$router.push({
        name: "detail",
        params: {id},
      });*/
    },
    toDetailNews(id){
      this.$router.push({
        name: "home-detail",
        params: { id },
      });
    },
    toAllVersion(){
      this.$router.push({
        name: "all-version",
        params: {  },
      });
    },
    goNewDetail(val){
      const url = val === null ? 'https://crowdsourcing.tntlinking.com' : val;
      window.open(url, '_blank');
    },
    formatDateTime(dateTime) {
      return moment(dateTime).format('YYYY-MM-DD HH:mm:ss');
    },
    truncatedInfoTitle(title) {
      const firstLine = title.substring(0, 20);
      const remainingText = title.substring(20);
      // 这里简单地返回两部分，实际中你可能需要根据需求调整，比如添加换行符
      return firstLine + '<br>' + remainingText;
    }
  },

  mounted() {
    this.getData();
  },
};
</script>

<style scoped lang="scss">
.truncate:hover {
  cursor: pointer;
  overflow: visible; /* 悬停时展示全部内容 */
  white-space: normal; /* 悬停时不再限制文本不换行 */
}
.image-container {
  position: relative;
  display: inline-block;
  box-shadow: none;
  border: 0;
}

.image-container img {
  display: block;
  max-width: 100%;
  height: auto;
  transition: transform 0.3s ease-in-out;
}

.image-container:hover img {
  transform: scale(1.05);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* 设置阴影效果 */
}


.home {
  &-banner {
    position: relative;
    img {
      width: 100%;
    }
    &-content {
      position: absolute;
      top: 140px;
      left: 360px;

      h1 {
        font-size: 48px;
        color: #333;
        margin-bottom: 40px;
      }
    }
  }
  table {
    border-collapse: collapse;
    width: 100%;

    td, th {
      border: 1px solid #ccc;
      padding: 8px;
    }

    td.left-column {
      width: 30%;
    }

    td.right-column {
      width: 70%;
    }
  }

  &-info {
    position: relative;
    z-index: 10;
    top: -50px;
    display: flex;
    justify-content: center;

    &-cell {
      width: 380px;
      height: 100px;
      display: flex;
      align-items: center;
      box-shadow: 4px 8px 8px 8px #f5f5f5;
      border-radius: 6px 6px 6px 6px;
      background-color: white;

      img {
        width: 40px;
        margin-right: 24px;
        margin-left: 24px;
      }

      .title {
        font-size: 18px;
        color: #333333;
        line-height: 35px;
      }

      .desc {
        font-size: 15px;
        color: #8791a1;
        line-height: 35px;
      }
    }

    &-cell + &-cell {
      margin-left: 24px;
    }
  }

  &-title {
    font-size: 36px;
    color: #262c33;
    line-height: 35px;
    text-align: center;
  }

  &-subtitle {
    margin: -3px;
    font-size: 20px;
    color: #262c33;
    line-height: 35px;
    text-align: center;
  }

  .divider {
    text-align: center;
    margin-top: -20px;

    label {
      display: inline-block;
      width: 240px;
      height: 1px;
      background-color: #0f1010;
    }
  }

  &-resolve {
    &-banner {
      margin: 0 auto;
      width: 1200px;
      position: relative;
      text-align: center;

      img {
        width: 1200px;
      }

      &-content {
        position: absolute;
        right: 70px;
        top: 105px;
      }
    }

    &-list {
      margin: 0 auto;
      width: 1200px;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }

    &-cell {
      display: flex;
      width: 542px;
      padding: 24px;
      box-shadow: 4px 8px 8px 8px #f5f5f5;
      border-radius: 6px 6px 6px 6px;
      background-color: white;
      margin-top: 12px;

      img {
        width: 64px;
        height: 64px;
        margin-right: 24px;
      }

      .title {
        font-size: 24px;
        color: #262c33;
        line-height: 35px;
      }

      .desc {
        font-size: 16px;
        color: #262c33;
        line-height: 24px;
        margin-top: 8px;
      }

      .op {
        margin-top: 8px;

        span {
          font-size: 14px;
          color: #797b7f;
          cursor: pointer;
        }

        span + span {
          margin-left: 10px;
        }
      }

      .op1 {

        span {
          font-size: 16px;
          color: #748db1;
          cursor: pointer;
        }

        span + span {
          margin-left: 20px;
        }
      }
    }
  }

  &-select {
    width: 100%;
    margin: 54px 0;
  }



  &-banner2 {
    position: relative;
    text-align: center;


    img {
      width: 100%;
    }
    &-title2 {
      font-size: 32px;
      color: #262c33;
      line-height: 20px;
      text-align: center;
    }

    &-subtitle2 {
      font-size: 18px;
      color: #262c33;
      text-align: center;
    }
    &-content2 {
      position: absolute;
      top: 120px;
      left: 50%;
      transform: translate(-50%, -50%);
      text-align: center;
    }



  }




  &-news {
    margin: 50px auto ;
    width: 1200px;
    //&-content {
    //  display: block;
    //  margin: 90px auto 0;
    //  width: 1170px;
    //
    //  img {
    //    width: 556px;
    //    margin-right: 70px;
    //  }
    //
    //  &-cell {
    //    padding-bottom: 40px;
    //    border-bottom: 1px solid #eee;
    //
    //    .title {
    //      font-size: 22px;
    //      color: #262c33;
    //      line-height: 35px;
    //    }
    //
    //    .title:hover {
    //      color: red;
    //      cursor: pointer;
    //    }
    //
    //    .desc {
    //      font-size: 16px;
    //      color: #a8abad;
    //      line-height: 24px;
    //      margin-top: 24px;
    //      overflow: hidden;
    //      text-overflow: ellipsis;
    //      display: -webkit-box;
    //      -webkit-line-clamp: 2;
    //      -webkit-box-orient: vertical;
    //    }
    //  }
    //
    //  &-cell + &-cell {
    //    padding-top: 24px;
    //  }
    //}
    //
    //&-more {
    //  display: flex;
    //  justify-content: center;
    //  margin-top: 42px;
    //  margin-bottom: 50px;
    //
    //  div {
    //    cursor: pointer;
    //    padding: 8px 50px;
    //    color: #333;
    //    display: flex;
    //    justify-content: center;
    //    align-items: center;
    //    border: 1px solid #333;
    //  }
    //}
  }
}
</style>
